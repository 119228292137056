import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Contact</h1>
        </div>

        <div className={"row"}>
          <div className={"col-7"}>
            <p>
              We take our commitment to our users seriously. If you need our
              help with your user account, have questions about how to use the
              Roadgaguge, any feedback or are experiencing technical
              difficulties, please do not hesitate to contact us.
            </p>
          </div>

          <div className={"col-5"}>
            <div className={"contact-items"}>
              <p>Plain email is the best way to contact us.</p>
              <h2>
                <a href="mailto:oleary.gabe@gmail.com">oleary.gabe@gmail.com</a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
